import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AniLink from "../../components/AniLink"

import { portfolioSchema1 as services } from "../../assets/js/helpers"

import opportunity from '../../assets/images/portfolio/top-remit/top-remit-opportunity.jpg';
import solution from '../../assets/images/portfolio/top-remit/portfolio-top-remit.png';
import how from '../../assets/images/portfolio/top-remit/top-remit-how.jpg';
import impact from '../../assets/images/portfolio/top-remit/top-remit-impact.jpg';
import hermanto from '../../assets/images/portfolio/top-remit/top-remit-hermanto.jpg';


function TopRemitPage() {
  return (
    <Layout>
      <SEO
        title="Portfolio - Top Remit"
        description="Connecting Users With Top Remit Online."
      />
      <section className="section-top-200">
        <div className="container">
          <div className="text-center">
            <div className="small-caption color-cerulean-blue">Finance</div>
            <div className="title color-raven-black">Top Remit</div>
            <div className="subtitle color-raven-black">
              We work with TopRemit to give their customers peace of mind
              sending money worldwide safe, low-cost, and online.
            </div>

            <div className="landing-service section-50">
              {services.map((value, key) => {
                return (
                  <AniLink
                    to={value.link}
                    key={key}
                    className="landing-service-item"
                  >
                    <img src={value.image} alt={value.id} />
                    <div className="landing-service-item-description">
                      {value.title}
                    </div>
                  </AniLink>
                )
              })}
            </div>
          </div>
        </div>
      </section>

      <section className="section-top-200">
        <div className="container">
          <div className="row">
            <div className="col-md-5 mb-4 mb-md-0">
              <img
                className="portfolio-image"
                src={opportunity}
              />
            </div>
            <div className="col-md-7 text-center text-md-left pl-md-5">
              <div className="subtitle color-cerulean-blue">OPPORTUNITY</div>
              <div className="description">
                Sending money overseas is a business that has been run by
                TopRemit offline, but it will become a tiring routine if it has
                to be done offline and is very limited in certain areas.
                Modeling an offline business online can capture more users and
                is more practical is the primary goal of running this project.
              </div>
            </div>
          </div>

          <div className="row section-top-150">
            <div className="col-md-7 pr-5 mb-4 mb-md-0">
              <div className="subtitle color-cerulean-blue mb-0">SOLUTION</div>
              <div className="description">
                <p className="heading color-raven-black">
                  Connecting Users With Top Remit Online.
                </p>
                <p>
                  While other money transfer businesses are competing to open
                  branches in many places, TopRemit has designed an online
                  platform. Minimizing the cost of opening an office so that it
                  can reduce the cost of sending money allows TopRemit to send
                  money abroad at a lower price, more practical and faster.
                </p>
                <p>
                  Sending money abroad through a bank account, cash pickup,
                  mobile wallet, or home delivery can all be done through the
                  website or TopRemit mobile application with an easy process.
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <img
                className="portfolio-image no-shadow"
                src={solution}
              />
            </div>
          </div>

          <div className="row section-top-150">
            <div className="col-md-5 mb-4 mb-md-0">
              <img
                className="portfolio-image"
                src={how}
              />
            </div>
            <div className="col-md-7 text-center text-md-left pl-md-5">
              <div className="subtitle color-cerulean-blue">HOW</div>
              <div className="description">
                <p>
                  As a technology supporter of the TopRemit business, Springkraf
                  has worked with the TopRemit team to jointly discuss this
                  platform, from the appearance, ways of working, efficiency, to
                  further developments.
                </p>
                <p>
                  Designing a good UI UX, reliable calculations, and matching
                  cloud server settings, making customers from TopRemit feel
                  safe and comfortable using it.
                </p>
              </div>
            </div>
          </div>

          <div className="row section-top-150">
            <div className="col-md-7 pr-5 mb-4 mb-md-0">
              <div className="subtitle color-cerulean-blue">IMPACT</div>
              <div className="description">
                <p className="heading color-raven-black">
                  Providing Better Sending Money Experience.
                </p>
                <p>
                  One year after it's launch, TopRemit managed to fold ten times its turnover. Increased turnover can mean that TopRemit has succeeded in helping more than ten times its offline users to be able to enjoy more efficient and convenient money transfers moreover become the best money transfer service in 2019.
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <img
                className="portfolio-image"
                src={impact}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="section-top-150 section-bottom-200">
        <div className="container">
          <div className="portfolio-testimonial">
            <div className="subtitle color-cerulean-blue">Testimonial</div>
            <div className="testimonial-profile">
              <img
                className="testimonial-picture"
                src={hermanto}
                alt="Top Remit CEO - Hermanto"
              />
              <div className="heading color-cerulean-blue">Hermanto</div>
              <div className="text-italic weight-light">CEO of TopRemit</div>
            </div>

            <div className="testimonial-text">
              <p className="text-bold">
                As a Fintech Company, our life depends on technology.
              </p>
              <p>
                Thanks to the genius behind, I feel like having my own
                development team. They are very good at communication that makes
                what’s in our head can deliver easily to be reality. The amazing
                part is they always deliver more than I expected.
              </p>
              <p>
                They have been handling our system for more than 2 years and it
                has been such an amazing experience. They are committed to this
                project from the start till the end and what I like the most it
                they figured out the weakness and always find a way to handle
                it.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="contact">
        <div className="contact">
          <div className="p-relative section-100 container text-center text-md-left">
            <div className="heading color-pine-green mb-2">
              Partner with us!
            </div>
            <div className="mb-5 color-spring-green">
              Start scaling up your business and be one of our successful
              partner!
            </div>
            <a
              href="https://api.whatsapp.com/send?phone=6285262798297&text=Hi%2C+Springkraf%21%0AMy+name+is%3A+%0AI+want+to+talk+about%3A&source&data&lang=en"
              target="_blank"
            >
              <div className="d-inline button button-primary">Chat with us</div>
            </a>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default TopRemitPage
